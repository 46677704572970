var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonLayout',[_c('div',{staticClass:"cabinet"},[_c('div',{staticClass:"cabinet__inner"},[_c('button',{staticClass:"button-default button-back uni-back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('span'),_vm._v("Назад ")]),_c('div',{staticClass:"cabinet__head"},[_c('div',{staticClass:"title"},[_vm._v("Участники команды “"+_vm._s(_vm.getTeam.teamName)+"”")])]),_c('div',{staticClass:"cabinet__body cabinet__body_type_rationg"},[_c('div',{staticClass:"cabinet__content"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"filter__title"},[_c('div',{staticClass:"uni-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTeam),expression:"searchTeam"}],attrs:{"type":"text","placeholder":"Поиск по ФИО"},domProps:{"value":(_vm.searchTeam)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTeam=$event.target.value}}})])]),_c('div',{staticClass:"filter__body"},[_c('div',{staticClass:"filter__caption"},[_vm._v("Сортировать по:")]),_c('div',{staticClass:"filter__controls"},_vm._l((_vm.sortOptions),function(item,index){return _c('button',{key:index,staticClass:"filter__button",class:{
                    active: item.active,
                    reverse: item.orderBy === 'desc',
                  },on:{"click":function($event){return _vm.handleSort(index, item.orderBy)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),_c('div',{staticClass:"cabinet-table"},[(_vm.loading)?_c('Loading',{attrs:{"position":"center"}}):_c('div',{staticClass:"cabinet-table__inner"},[(_vm.limitedList.length === 0)?_c('table',[_c('tbody')]):_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ФИО")]),_c('th',{attrs:{"width":"240px"}},[_c('div',{staticClass:"uni-switcher uni-switcher_type_mini cabinet-table-switcher",class:{ active: _vm.isActiveTotal }},[_c('button',{staticClass:"uni-switcher__button",on:{"click":function($event){_vm.isActiveTotal = !_vm.isActiveTotal}}},[_vm._v(" За игру ")]),_c('button',{staticClass:"uni-switcher__button",on:{"click":function($event){_vm.isActiveTotal = !_vm.isActiveTotal}}},[_vm._v(" На планете ")]),_c('Tooltip',{staticClass:"tooltip_style_dark",attrs:{"msg":"\n                            <table>\n                              <tr><td>За игру</td><td>Количество GPB-coins, полученных за <br/>все время</td></tr>\n                              <tr><td>На планете</td><td>Отображаются GPB-coins, полученные на <br/>текущей планете. Рейтинг обновляется <br/>еженедельно, данные при переходе на <br/>новую планету будут доступны со второй <br/>недели</td></tr>\n                            </table>\n                          "}})],1)]),_c('th',{attrs:{"width":"100px"}},[_vm._v("Место")])])]),(_vm.captain.edit)?_c('tbody',_vm._l((_vm.limitedList),function(item,index){return _c('tr',{key:index,class:{
                      disabled: _vm.isDisabled(_vm.captain.selected[index]),
                      selected: _vm.captain.selected[index],
                    },on:{"click":function($event){return _vm.choose(index)}}},[_c('td',[_c('div',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.captain.selected[index]),expression:"captain.selected[index]"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.captain.selected[index])?_vm._i(_vm.captain.selected[index],null)>-1:(_vm.captain.selected[index])},on:{"change":function($event){var $$a=_vm.captain.selected[index],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.captain.selected, index, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.captain.selected, index, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.captain.selected, index, $$c)}}}}),_c('span')]),_vm._v(" "+_vm._s(item.playerName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getTeam.calculatedCoinsCurrentIsland)+" ")]),_c('td',[_vm._v(_vm._s(item.playerCoins))])])}),0):_c('tbody',_vm._l((_vm.limitedList),function(item,index){return _c('router-link',{key:index,class:{ myself: item.id === _vm.getProfile.id },attrs:{"tag":"tr","to":_vm.linkToProfile(item.id)}},[_c('td',[_vm._v(_vm._s(item.playerName))]),_c('td',[_c('div',{staticClass:"uni-coins uni-coins_type_table"},[_c('img',{attrs:{"src":require("@/assets/img/pic2/coin.svg"),"width":"15","height":"15","alt":""}}),_vm._v(_vm._s(_vm.isActiveTotal ? item.playerCoins : _vm.weekCoinsFilter( item.calculatedCoinsCurrentIsland ))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.isActiveTotal ? item.ratingTotal : item.ratingIsland)+" ")])])}),1)]),_c('div',{staticClass:"cabinet-table__more"},[(
                    !_vm.searchTeam && _vm.limit && _vm.limit < _vm.getTeam.teamPlayers.length
                  )?_c('button',{on:{"click":function($event){_vm.limit = null}}},[_vm._v(" Смотреть все ")]):_vm._e()])])],1)]),_c('div',{staticClass:"cabinet__sidebar"},[_c('Dashboard',{attrs:{"view":_vm.typeDashboard}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }