import { render, staticRenderFns } from "./RatingTeamView.vue?vue&type=template&id=70230f30&scoped=true"
import script from "./RatingTeamView.vue?vue&type=script&lang=js"
export * from "./RatingTeamView.vue?vue&type=script&lang=js"
import style0 from "./RatingTeamView.vue?vue&type=style&index=0&id=70230f30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70230f30",
  null
  
)

export default component.exports